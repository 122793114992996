import * as React from 'react';
import { WorkInfo } from '../../pages/info';
import './styles.scss';

interface Props {
  info: WorkInfo;
}

const ClassItem: React.FunctionComponent<Props> = ({ info }) => {
  return (
    <div
      className="work-item"
    >
      <div className="work-item__time">
        <p>{info.time}</p>
      </div>
      <div>
        <div className="work-item__role">
          <h2>{info.role}</h2>
        </div>
        <div className="work-item__company">
          <p>{info.company}</p>
        </div>
        <div className="work-item__description">
          <p>{info.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ClassItem;
