import * as React from 'react';
import './styles.scss';

interface Props {
  header: string;
  content: string;
}

const InfoItem: React.FunctionComponent<Props> = ({ header, content }) => {
  return (
    <div className="info-item">
      <div className="info-item__type">
        <p>{header}</p>
      </div>
      <div>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default InfoItem;
