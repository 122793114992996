import { graphql, Link } from 'gatsby';
import * as React from 'react';
import InfoItem from '../components/info-item/component';
import Layout from '../components/layout/component';
import WorkItem from '../components/work-item/component';

interface InfoPageProps {
  data: {
    allWorkJson: {
      edges: WorkNode[];
    },
  };
}

interface WorkNode {
  node: {
    company: string;
    role: string;
    time: string;
    description: string;
  };
}

export interface WorkInfo {
  company: string;
  role: string;
  time: string;
  description: string;
}

export const InfoPageQuery = graphql`
  query InfoPageQuery {
    allWorkJson {
      edges {
        node {
          company
          role
          time
          description
        }
      }
    }
  }
`;

const InfoPage: React.FunctionComponent<InfoPageProps> = ({ data }) => {
  const workInfos: WorkInfo[] = data.allWorkJson.edges.map((workNode: WorkNode) => {
    return {
      company: workNode.node.company,
      description: workNode.node.description,
      role: workNode.node.role,
      time: workNode.node.time,
    };
  });

  const infoTabTitle = 'Info';
  const infoPageTitle = 'Hello!';
  const infoPageDescription = 'Luyi Zhang is a game developer based in San Francisco. Her past experiences includes software engineering, product management, and UI/UX design. Besides gaming, she enjoys bouldering, watching Great British Bake Off, and reading spooky novels.';
  const infoPageFootnote = (
    <div>
      *<Link to="/classes"> Click here </Link> to view LDT classes.
    </div>
  );

  return (
    <Layout
      tabTitle={infoTabTitle}
      title={infoPageTitle}
      description={infoPageDescription}
      footnote={infoPageFootnote}
    >
      {getWorkExperience(workInfos)}
      {getEducationInfo()}
      {getContactInfo()}
    </Layout>
  );
};

function getWorkExperience(workInfos: WorkInfo[]): JSX.Element {
  const workObjects = workInfos.map((w: WorkInfo) => {
    return (
      <WorkItem
        key={w.time}
        info={w}
      />
    );
  });

  return (
    <div>
      <h2>
        Recent Work Experience
      </h2>
      {workObjects}
    </div>
  );
}

function getEducationInfo(): JSX.Element {
  return (
    <div>
      <h2>
        Education
      </h2>
      <InfoItem header="2018 - 2019" content="Stanford University, MA (Learning, Design, and Technology)*" />
      <InfoItem header="2007 - 2011" content="Parsons the New School for Design, BFA" />
    </div>
  );
}

function getContactInfo(): JSX.Element {
  return (
    <div>
      <h2>
        Contact
      </h2>
      <InfoItem header="Email" content="luyizhng@gmail.com" />
    </div>
  );
}

export default InfoPage;
